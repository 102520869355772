import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="users"
export default class extends Controller {
  static targets = ["avatar", "avatarImage"];
  connect() {}

  avatarOnClick(event) {
    this.avatarTarget.click();
  }

  avatarOnChange(event) {}

  toggleTermAndCondition(event) {
    const termAndCondition = document.getElementById("termAndCondition");
    const checkedTermAndCondition = document.getElementById(
      "checkedTermAndCondition"
    );
    const uncheckedTermAndCondition = document.getElementById(
      "uncheckedTermAndCondition"
    );

    if (termAndCondition.value == 1) {
      checkedTermAndCondition.classList.remove("d-none");
      uncheckedTermAndCondition.classList.add("d-none");
      termAndCondition.value = 0;
    } else {
      checkedTermAndCondition.classList.add("d-none");
      uncheckedTermAndCondition.classList.remove("d-none");
      termAndCondition.value = 1;
    }
  }
}
