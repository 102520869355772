import "@hotwired/turbo-rails";
import "./controllers";
import "./dzsparallaxer";
import * as bootstrap from "bootstrap";
import "@fortawesome/fontawesome-free/js/all";

import "trix"
import "@rails/actiontext"
import "cropperjs/dist/cropper.min.js";

import Rails from "@rails/ujs";
Rails.start();
