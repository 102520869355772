import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["subEventBelongsToWrapper", "appliedForSubEventBooleanInput"];

  connect() {
    this.updateVisibility();
  }

  toggle(event) {
    this.updateVisibility();
  }

  updateVisibility() {
    const isChecked = this.appliedForSubEventBooleanInputTarget.checked;

    if (isChecked) {
      this.subEventBelongsToWrapperTarget.style.display = "";  // Show field
    } else {
      this.subEventBelongsToWrapperTarget.style.display = "none";  // Hide field
    }
  }
}
