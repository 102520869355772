import { Controller } from "@hotwired/stimulus"
import axios from "axios"

// Connects to data-controller="draft"
export default class extends Controller {
  static targets = ["form", "status", "input"];
  static values = { userId: Number };

  connect() {
    this.loadUserDraftStates();
  }

  loadUserDraftStates() {
    this.timeout = null;
    this.timeoutDuration = 1500;

    // hide status line
    this.statusTarget.hidden = true;

    this.userName = document.getElementById("user_user_name").value;
  }

  saveUserDraft() {
    clearTimeout(this.timeout);
    this.draftData = {
      user: {
        id: this.userIdValue == 0 ? null : this.userIdValue,
        user_name: this.userName
      }
    }
    this.timeout = setTimeout(() => {
      this.statusTarget.hidden = false;
      this.statusTarget.textContent = "Saving draft...";
      this.request(this.draftData, `/user_profile/save_draft`);
    }, this.timeoutDuration);
  }

  onInputUserName(event) {
    this.userName = event.target.value;
    this.saveUserDraft();
  }

  request(draftData, draftUrl) {
    this.headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
    }

    axios.post(draftUrl, draftData, { headers: this.headers })
      .then(() => {
        var time = new Date();
        this.statusTarget.textContent = `Draft last saved at ${time.toLocaleTimeString()}, on ${time.toLocaleDateString()}`;
      })
      .catch(() => {
        this.statusTarget.textContent = "Draft could not be saved. Please try again."
      });
  }
}
