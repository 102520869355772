import { Controller } from "@hotwired/stimulus";
import axios from "axios";

// Connects to data-controller="users"
export default class extends Controller {
  static targets = [
    "province",
    "district",
    "country",
    "address",
    "priceWrapper",
    "bibText",
  ];

  connect() {
    console.log("participants is connected");
  }

  countryOnChange(event, provinceId) {
    const countryId = event.target.value;
    const provinceSelect = this.provinceTargets.find(
      (target) =>
        target.dataset.participantId == event.target.dataset.participantId
    );

    provinceSelect.innerHTML = "";

    const defaultOption = document.createElement("option");
    defaultOption.text = "Chọn...";
    defaultOption.value = "";
    provinceSelect.add(defaultOption);

    axios
      .get(`/provinces?json=true&country_id=${countryId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const provinces = response.data;

        provinces.forEach((province) => {
          const option = document.createElement("option");
          option.text = province.name;
          option.value = province.id;
          option.selected = provinceId == province.id;
          provinceSelect.add(option);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  provinceOnChange(event, districtId) {
    const provinceId = event.target.value;
    const districtSelect = this.districtTargets.find(
      (target) =>
        target.dataset.participantId == event.target.dataset.participantId
    );

    districtSelect.innerHTML = "";

    const defaultOption = document.createElement("option");
    defaultOption.text = "Chọn...";
    defaultOption.value = "";
    districtSelect.add(defaultOption);

    axios
      .get(`/districts?json=true&province_id=${provinceId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const districts = response.data;

        districts.forEach((district) => {
          const option = document.createElement("option");
          option.text = district.name;
          option.value = district.id;
          option.selected = districtId == district.id;
          districtSelect.add(option);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateParticipant(event) {
    event.preventDefault();

    const participantId = event.currentTarget.dataset.participantId;
    const formElement = document.getElementById(`participant-${participantId}`);
    const form = new FormData(formElement);

    // TODO: need to show toast
    axios({
      method: "put",
      url: `/participants/${participantId}`,
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
      },
    })
      .then((response) => {
        this.showToast("Cập nhật người tham gia", "Cập nhật thành công.");
      })
      .catch((error) => {
        this.showToast(
          "Cập nhật người tham gia",
          "Có lỗi xảy ra, vui lòng thử lại sau."
        );
      });
  }

  showToast(title, message) {
    const toast = document.getElementById("toast-update-participant");
    const toastMessage = document.getElementById("toast-message");
    const toastTitle = document.getElementById("toast-title");

    toastTitle.innerHTML = title;
    toastMessage.innerHTML = `<div>${message}</div>`;
    toast.classList.add("show");
    toast.classList.remove("hide");

    setTimeout(() => {
      if (!toast.classList.contains("hide")) {
        toast.classList.add("hide");
        toast.classList.remove("show");
      }
    }, 5000);
  }

  updateAnswer(event) {
    event.preventDefault();

    const form = new FormData(event.target);
    // TODO: need to show toast
    axios({
      method: "patch",
      url: "/event_registration_answers/batch_upsert",
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
      },
    })
      .then((response) => {
        this.showToast("Cập nhật câu trả lời", "Cập nhật thành công.");
      })
      .catch((error) => {
        this.showToast(
          "Cập nhật câu trả lời",
          "Có lỗi xảy ra, vui lòng thử lại sau."
        );
      });
  }

  generateBib(event) {
    const participantId = event.target.dataset.participantId;
    const createBibPath = `/participants/${participantId}/bibs`;
    const token = document.getElementsByName("csrf-token")[0].content;
    this.headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    };
    const loadingIndicator = this.element.querySelector(
      `.loading-indicator[data-participant-id="${participantId}"]`
    );

    event.target.style.display = "none";
    loadingIndicator.style.display = "block";

    axios
      .post(createBibPath, {}, { headers: this.headers })
      .then((response) => {
        const newBibNumber = response.data.bib_number;
        const bibId = response.data.id;

        if (
          confirm(`New Bib Number: ${newBibNumber}. Do you want to select it?`)
        ) {
          axios
            .patch(
              `/participants/${participantId}/bibs/${bibId}/select_bib`,
              {},
              {
                headers: this.headers,
              }
            )
            .then((response) => {
              this.bibTextTarget.innerHTML = `- ${newBibNumber}`;
            })
            .catch((error) => {
              console.error("Error selecting bib:", error);
            });
        } else {
          axios
            .patch(
              `/participants/${participantId}/bibs/${bibId}/discard_bib`,
              {},
              {
                headers: this.headers,
              }
            )
            .then((response) => {
              console.log("Bib discarded successfully:", response.data);
            })
            .catch((error) => {
              console.error("Error discarding bib:", error);
            });
        }

        event.target.style.display = "block";
        loadingIndicator.style.display = "none";
      })
      .catch((error) => {
        console.error("Error generating bib:", error);
      });
  }

  triggerFileInput(event) {
    const name = event.target.name;
    const inputSuffix = name.split("file_display_question_")[1];
    const input = document.getElementById(`question_${inputSuffix}`);

    input.click();
  }

  fileInputChanged(event) {
    if (event.target.files.length > 0) {
      const id = event.target.id;
      const inputSuffix = id.split("question_")[1];
      const input = document.querySelector(
        `input[name="file_display_question_${inputSuffix}"]`
      );

      input.value = event.target.files[0].name;

      const hiddenInput = document.querySelector(
        `input[name="question[${inputSuffix}][existing_file]"`
      );

      hiddenInput.value = null;
    }
  }

  subEventOnChange(event) {
    const participantId = event.target.dataset.participantId;
    const newSubEventId = event.target.value;

    const url = `/calculate_price_change?participant_id=${participantId}&sub_event_id=${newSubEventId}`;

    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (!response.data) {
          return;
        }

        const data = response.data;

        if (data.error) {
          // toast error
          alert(data.error);
          return;
        }

        if (data.price) {
          this.priceWrapperTarget.innerHTML = `Giá: ${data.price}`;
          document.getElementById("amount").value = data.price;
        } else {
          this.priceWrapperTarget.innerHTML = "Miễn phí";
        }
      });
  }

  searchBib() {
    const bib = document.getElementById("bib").value;
    const param = bib.length ? `&bib=${bib}` : "";
    window.location.href = `${window.location.href.split("&bib")[0]}${param}`;
  }
}
