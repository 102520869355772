import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="events"
export default class extends Controller {
  categories = [
    { id: "#upcoming_races", finished: false },
    { id: "#finished_races", finished: true }
  ];

  routePaging = "events/load_events";

  connect() {
    // this.init();
    // We don't use stimulus to render the view anymore
  }

  init() {
    for (const category of this.categories) {
      this.loadRaces(
        `${this.routePaging}?page=1&finished=${category.finished}`,
        category
      );
    }
  }

  loadEventListeners() {
    for (const category of this.categories) {
      for (const element of document.querySelectorAll(
        `${category.id}_nav li.page-item a.page-link`
      )) {
        element.addEventListener("click", (event) => {
          event.preventDefault();
          if (!event.target.classList.contains("active")) {
            const href = event.target.href.includes("load_events")
              ? event.target.href
              : event.target.href.replace("events", "events/load_events");
            this.loadRaces(href, category);
          }
        });
      }
    }
  }

  loadRaces(destination, category) {
    fetch(destination)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        this.renderRaces(data, category);
      })
      .catch((error) => {
        console.error("Failed to load races:", error);
      });
  }

  renderRaces(data, category) {
    // replace html
    document.querySelector(`${category.id} .list-events`).innerHTML = data.html;

    document.querySelector(`${category.id}_nav`).innerHTML = this.decodeHTML(
      data.pagy_nav
    );

    this.loadEventListeners();
  }

  decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    const decode = txt.value;

    txt.remove();
    return decode;
  }
}
