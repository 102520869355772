import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton"];

  connect() {
    this.submitButtonTarget.setAttribute('disabled', 'true');
  }

  handleSelection(event) {
    const selectedOption = event.target.value;

    if (selectedOption) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  validateForm(event) {
    const selectedOption = this.paymentOptionsTarget.querySelector('input[type="radio"]:checked');

    if (!selectedOption) {
      event.preventDefault();
      alert("Bạn phải chọn hình thức thanh toán để tiếp tục");
    }
  }
}
