import { Controller } from "@hotwired/stimulus";
import axios from "axios";

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = [
    "birthday",
    "email",
    "firstName",
    "lastName",
    "gender",
    "idCard",
    "phone",
    "deleteParticipationButton",
    "participantId",
    "providedFor",
    "subEvent",
    "joinAs",
    "teamId",
    "teamName",
    "team",
    "participants",
    "province",
    "district",
    "country",
    "address",
    "price",
    "priceContainer",
  ];

  connect() {
    this.currentUser = JSON.parse(this.element.dataset.userInfo) || {};
    this.initOptions();
    this.subEventQuestions = [];
  }

  providedForOnChange(event) {
    if (event.target.value === "self") {
      this.fillForm(this.currentUser);
    } else {
      this.clearForm();
    }
  }

  clearCustomQuestionAnswer() {
    const elements = document.querySelectorAll("[name*='question_']");
    for (const element of elements) {
      if (["radio", "checkbox"].includes(element.type) && element.checked) {
        element.checked = false;
        continue;
      }

      if (element.value) {
        element.value = null;
      }
    }
  }

  clearForm() {
    this.firstNameTarget.value = "";
    this.lastNameTarget.value = "";
    this.emailTarget.value = "";
    this.phoneTarget.value = "";
    this.idCardTarget.value = "";
    this.genderTarget.value = "";

    document.getElementById("birthday_written_on_1i").value = "";
    document.getElementById("birthday_written_on_2i").value = "";
    document.getElementById("birthday_written_on_3i").value = "";
  }

  fillForm(data) {
    this.firstNameTarget.value = data.first_name;
    this.lastNameTarget.value = data.last_name;
    this.birthdayTarget.value = data.birthday;
    this.emailTarget.value = data.email;
    this.phoneTarget.value = data.phone;
    this.idCardTarget.value = data.id_card;
    this.genderTarget.value = data.gender;

    if (data.sub_event_id) {
      this.subEventTarget.value = data.sub_event_id;
    }

    if (data.team?.id) {
      this.teamIdTarget.value = data.team.id;
      this.teamNameTarget.value = data.team.name;
      this.teamNameTarget.required = true;

      for (const target of this.joinAsTargets) {
        target.checked = target.value === "team";
      }
    }

    if (data.country_id) {
      this.countryTarget.value = data.country_id;
      this.countryOnChange(
        { target: { value: data.country_id } },
        data.province_id
      );
    }

    if (data.province_id) {
      this.provinceTarget.value = data.province_id;
      this.provinceOnChange(
        { target: { value: data.province_id } },
        data.district_id
      );
    }

    if (data.district_id) {
      this.districtTarget.value = data.district_id;
    }

    this.addressTarget.value = (data.address !== null && data.address !== undefined) ? data.address : "";

    const birthdayParts = data.birthday.split("-");
    const year = birthdayParts[0];
    const month = birthdayParts[1];
    const day = birthdayParts[2];

    document.getElementById("birthday_written_on_1i").value = parseInt(year);
    document.getElementById("birthday_written_on_2i").value = parseInt(month);
    document.getElementById("birthday_written_on_3i").value = parseInt(day);

    if (data.id) {
      this.participantIdTarget.value = data.id;
      const valueToSelect =
        data.email === this.currentUser.email ? "self" : "someone-else";

      this.providedForTargets.forEach((radioButton) => {
        if (radioButton.value === valueToSelect) {
          radioButton.checked = true;
        }
      });

      if (data.event_registration_answers?.length) {
        for (const answer of data.event_registration_answers) {
          if (!answer.custom_question) {
            continue;
          }

          const inputSuffix = `${answer.custom_question_id}`;
          const hiddenInputId = document.querySelector(
            `input[name="registrant[question_${inputSuffix}][id]"`
          );

          if (hiddenInputId) {
            hiddenInputId.value = answer.id;
          }

          if (answer.answer) {
            const inputType =
              answer.custom_question.answer_type === "multiple_line"
                ? "textarea"
                : "input";
            const element = document.querySelector(
              `${inputType}[name="registrant[question_${inputSuffix}][answer]"]`
            );

            if (element) {
              element.value = answer.answer;
            }
          }

          if (answer.file_upload_metadata) {
            const hiddenInput = document.querySelector(
              `input[name="registrant[question_${inputSuffix}][existing_file]"`
            );
            hiddenInput.value = answer.file_upload_metadata.filename;

            const displayInput = document.querySelector(
              `input[name="file_display_question_${inputSuffix}"]`
            );
            displayInput.value = answer.file_upload_metadata.filename;
          }

          if (answer.question_options.length) {
            switch (answer.custom_question.multiple_choice_type) {
              case "checkbox":
                const checkboxInputs = document.querySelectorAll(
                  `input[type="checkbox"][id^="registrant_question_${inputSuffix}"]`
                );

                checkboxInputs.forEach((checkbox) => {
                  checkbox.checked = answer.question_options.some(
                    (option) => option.id == checkbox.value
                  );
                });

                const hiddenInput = document.getElementById(
                  `checkbox_${inputSuffix}`
                );
                hiddenInput.value = answer.question_options.reduce(
                  (val, option) => (val += option.id),
                  ""
                );

                break;
              case "radio_button":
                const radioInputs = document.querySelectorAll(
                  `input[type="radio"][id^="registrant_question_${inputSuffix}"]`
                );

                radioInputs.forEach((radio) => {
                  radio.checked = answer.question_options.some(
                    (option) => option.id == radio.value
                  );
                });
                break;
              case "dropdown":
                const selectElements = document.querySelectorAll(
                  `select[id^="registrant_question_${inputSuffix}"]`
                );

                selectElements.forEach((select) => {
                  const options = select.querySelectorAll("option");

                  options.forEach((selectOption) => {
                    selectOption.selected = answer.question_options.some(
                      (option) => option.id == selectOption.value
                    );
                  });
                });

                break;

              default:
                break;
            }
          }
        }
      }
    }
  }

  toggleCheckbox(event) {
    const checked = event.target.checked;
    const optionId = event.target.value;
    const questionId = event.target.dataset.questionId;

    const hiddenInput = document.getElementById(`checkbox_${questionId}`);
    if (!hiddenInput) {
      return;
    }

    if (checked && !hiddenInput.value.includes(optionId)) {
      hiddenInput.value += optionId;
    } else {
      hiddenInput.value = hiddenInput.value.replace(optionId, "");
    }
  }

  triggerFileInput(event) {
    const name = event.target.name;
    const inputSuffix = name.split("file_display_question_")[1];
    const input = document.getElementById(`registrant_question_${inputSuffix}`);

    input.click();
  }

  fileInputChanged(event) {
    if (event.target.files.length > 0) {
      const id = event.target.id;
      const inputSuffix = id.split("registrant_question_")[1];
      const input = document.querySelector(
        `input[name="file_display_question_${inputSuffix}"]`
      );

      input.value = event.target.files[0].name;

      const hiddenInput = document.querySelector(
        `input[name="registrant[question_${inputSuffix}][existing_file]"`
      );

      hiddenInput.value = null;
    }
  }

  subEventOnChange(event) {
    //TODO: disable/enable question for sub event only

    const selectedOption = this.subEventTarget.selectedOptions[0];
    if (selectedOption) {
      const price = selectedOption.dataset.price;

      if (price) {
        this.priceTarget.textContent = this.formatCurrency(price);
        this.priceContainerTarget.classList.remove("d-none");
      } else {
        this.priceContainerTarget.classList.add("d-none");
        this.priceTarget.textContent = "";
      }
    }
  }

  fetchRegistration(event) {
    event.preventDefault();

    const registrationId = event.currentTarget.dataset.registrationId;

    if (registrationId) {
      axios
        .get(`/my_registration/${registrationId}/participants`)
        .then((response) => {
          const participants = response.data;
          const selectText = document.getElementById("no-participants");
          if (participants && participants.trim()) {
            selectText.style.display = "none";
            this.participantsTarget.innerHTML = participants;
          } else {
            selectText.style.display = "block";
          }
        })
        .catch((error) => {
          console.error("Error fetching registration:", error);
        });
    }
  }

  initOptions() {
    if (!this.years || !this.years.length) {
      this.years = [];
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 1900; i--) {
        this.years.push(`<option value="${i}">Năm ${i}</option>`);
      }
    }

    if (!this.months || !this.months.length) {
      this.months = [];
      for (let i = 1; i <= 12; i++) {
        this.months.push(`<option value="${i}">Tháng ${i}</option>`);
      }
    }

    if (!this.days || !this.days.length) {
      this.days = [];
      for (let i = 1; i <= 31; i++) {
        this.days.push(`<option value="${i}">Ngày ${i}</option>`);
      }
    }
  }

  participantOnChange(event) {
    const selectedValue = event.target.value;

    if (selectedValue) {
      this.deleteParticipationButtonTarget.classList.remove("disabled");
      this.deleteParticipationButtonTarget.removeAttribute("disabled");
      this.selectedParticipationId = selectedValue;
      axios
        .get(`/participants/${selectedValue}`, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          this.fillForm({
            ...response.data.user,
            ...response.data,
            gender: response.data.user?.gender,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.deleteParticipationButtonTarget.classList.add("disabled");
      this.deleteParticipationButtonTarget.setAttribute("disabled", true);
      this.clearForm();
      this.clearCustomQuestionAnswer();
    }
  }

  confirmDelete(event) {
    event.preventDefault();

    const deleteUrl = `/participants/${this.selectedParticipationId}`;
    axios
      .delete(deleteUrl, {
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        alert("There was an error deleting the participation.");
      });
  }

  joinAsOnChange(event) {
    const isJoinAsTeam = event.target.value === "team";
    this.teamNameTarget.required = isJoinAsTeam;

    if (!isJoinAsTeam) {
      this.teamTarget.innerHTML = null;
      this.teamIdTarget.value = null;
      document.getElementById("input_team_free_text").value = null;
    }
  }

  searchTeamName(event) {
    const name = event.target.value.trim();

    for (const target of this.joinAsTargets) {
      if (target.value === "alone") {
        target.checked = !name;
      } else {
        target.checked = !!name;
      }
    }

    if (!name) {
      this.teamTarget.innerHTML = null;
      this.teamIdTarget.value = null;
      return;
    }

    if (this.teamName !== name) {
      this.teamName = name;

      axios
        .get(`/teams?name=${this.teamName}`, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.data?.length) {
            this.teamTarget.innerHTML = response.data
              .map(
                (team) =>
                  `<div class="team-info card-body ${
                    team.id == this.teamIdTarget.value ? "active" : ""
                  }" data-team-id="${
                    team.id
                  }" data-action="click->registration#teamOnSelect">${
                    team.name
                  }</div>`
              )
              .join("");
          } else {
            this.teamIdTarget.value = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  teamOnSelect(event) {
    for (const element of this.teamTarget.children) {
      element.classList.remove("active");
    }

    event.target.classList.add("active");
    this.teamIdTarget.value = event.target.dataset.teamId;
    this.teamNameTarget.value = event.target.textContent;
  }

  countryOnChange(event, provinceId) {
    const countryId = event.target.value;

    axios
      .get(`/provinces?json=true&country_id=${countryId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const provinces = response.data;
        const provinceSelect = this.provinceTarget;

        provinceSelect.innerHTML = "";
        provinceSelect.value = provinceId;

        const defaultOption = document.createElement("option");
        defaultOption.text = "Chọn...";
        defaultOption.value = "";
        provinceSelect.add(defaultOption);

        provinces.forEach((province) => {
          const option = document.createElement("option");
          option.text = province.name;
          option.value = province.id;
          option.selected = provinceId == province.id;
          provinceSelect.add(option);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  provinceOnChange(event, districtId) {
    const provinceId = event.target.value;

    axios
      .get(`/districts?json=true&province_id=${provinceId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const districts = response.data;
        const districtSelect = this.districtTarget;

        districtSelect.innerHTML = "";
        districtSelect.value = districtId;

        const defaultOption = document.createElement("option");
        defaultOption.text = "Chọn...";
        defaultOption.value = "";
        districtSelect.add(defaultOption);

        districts.forEach((district) => {
          const option = document.createElement("option");
          option.text = district.name;
          option.value = district.id;
          option.selected = districtId == district.id;
          districtSelect.add(option);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  formatCurrency(value) {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
      minimumFractionDigits: 0
    }).format(value);
  }


  formOnSubmit(event) {
    const hiddenCheckboxQuestions = document.querySelectorAll(
      `input[type="hidden"][id^="checkbox_"]`
    );
    for (const element of hiddenCheckboxQuestions) {
      if (!element.value?.length && !!element.dataset.require) {
        event.preventDefault();
        element.parentElement.scrollIntoView();
        return;
      }
    }

    const validateInput = (input, pattern, errorMessage) => {
      if (!pattern.test(input.value)) {
        event.preventDefault();
        input.scrollIntoView({ behavior: 'smooth' });
        const error = document.createElement('div');
        error.textContent = errorMessage;
        error.style.color = 'red';
        input.parentElement.appendChild(error);
        return false;
      }
      return true;
    };

    const idOrPassportPattern = /^(?:\d{12}|[A-Z0-9]{6,9})$/; // 12 digits for ID or 6-9 alphanumeric for passport
    const isValidInput = 
      validateInput(this.phoneTarget, /^\d{10,11}$/, "Vui lòng nhập số điện thoại hợp lệ (10 đến 11 chữ số).") &&
      validateInput(this.idCardTarget, idOrPassportPattern, "Vui lòng nhập số CCCD hợp lệ (12 chữ số) hoặc số hộ chiếu hợp lệ (6-9 ký tự).");

    if (!isValidInput) {
      return;
    }
  }
}
