import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import * as d3 from "d3";

export default class extends Controller {
  static targets = ["container"];
  padding = { top: 20, right: 40, bottom: 0, left: 0 };
  width = 500 - this.padding.left - this.padding.right;
  height = 500 - this.padding.top - this.padding.bottom;
  radius = Math.min(this.width, this.height) / 2;
  postHeaders = {
    "Content-Type": "application/json",
    "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
  };

  connect() {}

  drawWheel() {
    d3.select("#wheel").selectAll("svg").remove();

    const color = d3
      .scaleOrdinal()
      .domain(this.prizes.map((d) => d.product.name))
      .range(d3.schemeCategory10);

    const svg = d3
      .select("#wheel")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`);

    this.wheel = svg.append("g");

    const pie = d3.pie().value((d) => 1);

    const arc = d3.arc().innerRadius(0).outerRadius(this.radius);

    this.wheel
      .selectAll("path")
      .data(pie(this.prizes))
      .join("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.product.name))
      .attr("stroke", "white")
      .style("stroke-width", "2px");

    this.wheel
      .selectAll("text")
      .data(pie(this.prizes))
      .join("text")
      .attr("transform", (d) => {
        const [x, y] = arc.centroid(d);
        const angle = ((d.startAngle + d.endAngle) / 2) * (180 / Math.PI);
        return `translate(${x}, ${y}) rotate(${angle - 90})`;
      })
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text((d) => d.data.product.name)
      .style("font-size", "12px")
      .style("fill", "white");

    svg
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 30)
      .attr("fill", "#333")
      .style("cursor", "pointer")
      .attr("stroke", "white")
      .style("stroke-width", "2px")
      .on("click", () => this.spin());

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", 5)
      .attr("text-anchor", "middle")
      .text("Quay")
      .style("fill", "white")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .style("pointer-events", "none");

    svg
      .append("path")
      .attr("d", "M 210 0 L 230 -5 L 230 5 Z")
      .attr("fill", "red")
      .attr("stroke", "black")
      .attr("stroke-width", "1px");
  }

  spin() {
    let currentRotation = 0;
    const spinSpeed = 10;

    this.spinningInterval = setInterval(() => {
      currentRotation = (currentRotation + spinSpeed) % 360;
      this.wheel.attr("transform", `rotate(${currentRotation})`);
    }, 20);

    axios
      .post(
        "/spin_attempts/spin",
        { event_id: this.eventId },
        { headers: this.postHeaders }
      )
      .then((response) => {
        const targetId = response.data.product_prize_id;

        const targetData = this.prizes.find((d) => d.id === targetId);
        if (!targetData) {
          clearInterval(this.spinningInterval);
          return;
        }

        const pie = d3.pie().value(() => 1);
        const arcs = pie(this.prizes);
        const targetArc = arcs.find((d) => d.data.id === targetId);

        const startAngle = targetArc.startAngle * (180 / Math.PI);
        const endAngle = targetArc.endAngle * (180 / Math.PI);
        const targetAngle =
          startAngle + Math.random() * (endAngle - startAngle);

        clearInterval(this.spinningInterval);

        const finalRotation = 360 * 5 + targetAngle;
        this.wheel
          .transition()
          .duration(3000)
          .ease(d3.easeCubicOut)
          .attrTween("transform", () => {
            return d3.interpolateString(
              `rotate(${currentRotation})`,
              `rotate(${finalRotation})`
            );
          });
      })
      .catch((error) => {
        clearInterval(this.spinningInterval);
      });
  }

  showReceivedPrizes() {
    const wrapper = document.getElementById("received-prizes");
    wrapper.innerHTML = `
      <hr/>
      <h4>Các vật phẩm đã nhận: </h4>
      ${this.receivedPrizes
        .map(
          (prize) =>
            `<p class="mb-3">- ${prize.product_name}: ${prize.total_quantity}</p>`
        )
        .join("")}`;
  }

  eventOnChange(event) {
    this.eventId = event.target.value;
    axios
      .get(`get_prizes_by_event?event_id=${this.eventId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data) {
          this.prizes = response.data.prizes;
          this.receivedPrizes = response.data.received_prizes;
          this.drawWheel();
          this.showReceivedPrizes();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
